import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"

import { Link } from "gatsby"

const termsAndConditions = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="Terms & Conditions"
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container inner-y-padding">
        <h1>Terms & Conditions</h1>
        <p>
          By using this site you may be opted in to our Rocky Brands mailing
          list and may receive future promotions from any of our brands and/or
          web sites. To view a list of brands, visit{" "}
          <Link to="/brands">Our family of Brands</Link>.
        </p>

        <h3>Use of Site</h3>
        <p>
          By use of this Rocky Brands (“Rocky”) website (the “Site”), you agree
          to all of the terms, conditions, and notices contained or referenced
          in this Site (the “Terms and Conditions”). If you do not agree to
          these Terms and Conditions, do not use this Site. Rocky reserves the
          right to update or revise these Terms and Conditions. Your continued
          use of this Site following the posting of any changes to the Terms and
          Conditions constitutes acceptance of those changes.
        </p>

        <h3>Copyright</h3>
        <p>
          The entire content of this Site is protected by copyright. You may not
          copy, distribute, or create derivative works from any part of this
          website (including its graphics, pictorial matter, and text) without
          the prior written consent of Rocky unless otherwise expressly
          permitted by the Site.
        </p>

        <h3>Trademarks</h3>
        <p>
          Rocky Brands are trademarks, service marks, or tradenames of Rocky and
          may not be used without the prior written permission of Rocky. Rocky
          claims no interest in marks owned by other entities not affiliated
          with Rocky which may appear on this Site.
        </p>

        <h3>No Confidentiality</h3>
        <p>
          Should you choose to contact Rocky through the{" "}
          <Link to="../about-us/contact-us">“Contact Us”</Link> link with any
          information, feedback, data, questions, comments, suggestions or the
          like regarding the content of this Site, the content of any such
          response shall be deemed not to be confidential and Rocky shall be
          free to reproduce, use, disclose and distribute the response to others
          without limitation or compensation, provided that Rocky will always
          comply with its Privacy Policy posted on this Site. Further, by
          choosing to submit a comment or message to Rocky through the “Contact
          Us” link, you agree to assign to Rocky all copyright interests in your
          questions, comments, suggestions, or commentary, and agree that Rocky
          is free to use the contents of your message, or derivatives thereof,
          without limitation. Rocky Brands makes available our Guidelines for
          Online and Social Commenting (in PDF format) by clicking here.
        </p>

        <h3>Rocky Mail Sections</h3>
        <p>
          Should you submit your contact information through the “Mail” link, or
          should you choose to leave the checkmark in the box next to such words
          as “subscribe to the Newsletter” when contacting Rocky through the
          “Contact Us” link, you agree to receive periodic promotional emails
          relating to product information, coupons, and discounts offered by
          Rocky and the wholly owned subsidiaries of Rocky Brands, Inc. unless
          and until you choose to opt out of receiving the email messages
          through the opt out provisions included in the promotional emails.
        </p>

        <h3>No Links; No Endorsement</h3>
        <p>
          Any links to third party web sites are not an endorsement,
          sponsorship, or recommendation of the third parties or the third
          parties' ideas, products, or services. Similarly, any references in
          this Site to third parties and their products or services do not
          constitute an endorsement, sponsorship, or recommendation.
        </p>

        <h3>No Guarantee of International Service or Compliance</h3>
        <p>
          This Site was designed for and is operated in the United States. If
          this Site is viewed internationally, you are responsible for
          compliance with applicable local laws
        </p>

        <h3>Disclaimer of Warranties</h3>
        <p>
          The material in this web site is provided “as is,” without any kind of
          warranty. The material on this web site is not a warranty as to any
          product or service provided by rocky outdoor gear, rocky brands, inc.,
          or any of the rocky brands, inc. Subsidiaries. Warranties, if any,
          accompany the product or service when purchased by a customer.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          Rocky is not liable for any errors, delays, inaccuracies, or omissions
          in this site or any sites that are linked to, or referred to by, this
          site. Under no circumstances shall rocky be liable for any damages,
          including indirect, incidental, special, or consequential damages that
          result from the use of, or inability to use, this site whether based
          on actions in contract, tort, negligence, strict liability, or
          otherwise, even if rocky has been advised of the possibility of such
          damage.
        </p>

        <h3>Jurisdiction and Venue</h3>
        <p>
          You and Rocky agree that the laws of the State of Ohio will apply to
          all matters arising from or relating to use of this Site, whether for
          claims in contract, tort, or otherwise, without regard to conflicts of
          laws principles. You and Rocky also agree and hereby submit to the
          exclusive personal jurisdiction and venue of the Common Pleas Court of
          Franklin County, Ohio and the United States District Court for the
          Southern District of Ohio with respect to such matters.
        </p>

        <h3>Entire Agreement</h3>
        <p>
          These Terms and Conditions constitute the entire agreement between you
          and Rocky with respect to your use of this Site and supersede all
          prior or contemporaneous communications and proposals, whether oral,
          written, or electronic, between you and Rocky with respect to this
          Site. If any provision(s) of these Terms and Conditions are held
          invalid or unenforceable, those provisions shall be construed in a
          manner consistent with applicable law to reflect, as nearly as
          possible, the original intentions of the parties, and the remaining
          provisions shall remain in full force and effect.
        </p>

        <p>
          Copyright © {new Date().getFullYear()} Rocky Brands. All Rights
          Reserved
        </p>
      </div>
    </Layout>
  )
}

export default termsAndConditions
